import React from "react";
import ModelLayout from "../../components/models/modelLayout";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./cercospora-leaf-spot-of-table-beets.json";
import Acknowledgments from "./_acknowledgements";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsGraph from "./_resultsGraph";
import ResultsTable from "./_resultsTable";

const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

export default function BeetCercosporaLeafSpot() {
  const h1Text = modelData.title;
  const { user, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  const { sdate, edate } = sdateEdate(dateOfInterest, modelData.seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);

  let mData = null;
  if (data && !isLoading) {
    mData = modelLogic(data, dateOfInterest);
  }
  // console.log(mData)
  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showResultsTable, setShowResultsTable] = React.useState(true);
  const [showResultsGraph, setShowResultsGraph] = React.useState(false);
  const isVisible = station && mData && !isLoading;

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      titleSize={"text-xl lg:text-2xl"}
      moreInfo={<MoreInfo moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      showResultsChart={showResultsGraph}
      setShowResultsChart={setShowResultsGraph}
      tutorialLink={modelData.tutorialLink}
      h1Text={h1Text}
    >
      {/* Results Table */}
      {isModelInSeason && isVisible && showResultsTable && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsTable
            station={station}
            modelData={modelData}
            tableData={mData.tableData}
            csvData={mData.csvData}
          ></ResultsTable>
        </div>
      )}

      {/* Results Graph */}
      {isModelInSeason && isVisible && showResultsGraph && (
        <div
          className="mb-16 sm:mb-18 md:mb-20"
          aria-label="A line Graph of Results Data"
        >
          <ResultsGraph
            modelData={modelData}
            data={mData.graphData}
          ></ResultsGraph>
        </div>
      )}
    </ModelLayout>
  );
}

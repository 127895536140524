import domtoimage from "dom-to-image"
import { saveAs } from "file-saver"
import React from "react"
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Cell,
} from "recharts"

const CustomXLabel = (props) => {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
        transform="rotate(-15)"
      >
        {payload.value}
      </text>
    </g>
  )
}

const CustomYLabel = (props) => {
  const { x, y, payload, unit } = props
  return (
    <g>
      <text
        x={x}
        y={y}
        dy={5}
        dx={-15}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
      >
        {payload.value}
        {unit}
      </text>
    </g>
  )
}

const renderTooltip = ({ payload }) => {
  if (typeof payload === "undefined") return null
  return payload.map((entry, i) => {
    if (i === 0) {
      return (
        <div
          key={i}
          className="flex flex-col px-4 py-3 bg-gray-100 rounded opacity-90"
        >
          <div>
            <span className="mr-1 text-xs font-semibold">Date:</span>
            <span className="text-xs">{entry.payload.dateDisplay}</span>
          </div>
          <div className="mt-2">
            <span className="mr-1 text-xs font-semibold">
              2-Day Infection Value:
            </span>
            <span className="mr-1 text-xs font-semibold">
              {entry.payload.div2Days}
            </span>
          </div>
        </div>
      )
    } else {
      return null
    }
  })
}

export default function ResultsGraph({ modelData, data }) {
  const { resultsGraph } = modelData.elements

  function downloadPNG() {
    domtoimage
      .toBlob(document.getElementById("chart"))
      .then((blob) => saveAs(blob, `${modelData.title} Chart.png`))
  }

  if (!data) return null
  return (
    <div className="w-full" aria-hidden="true">
      <div className="flex items-center justify-between mb-6">
        <h2 className="inline-block text-lg font-semibold text-gray-600 sm:text-xl md:text-2xl">
          {resultsGraph.title}
        </h2>

        <div className="flex items-center justify-center rounded-md">
          <button
            type="button"
            aria-label="download graph in png format"
            onClick={downloadPNG}
            className="inline-flex items-center p-2 text-sm font-bold leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4 text-white"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>
            <span className="hidden mr-1 sm:inline-block">Download </span>{" "}
            <span className="text-white">PNG</span>
          </button>
        </div>
      </div>

      <div
        id="chart"
        className="relative w-full bg-white border-b border-gray-200 shadow sm:rounded-lg"
      >
        <h2
          className={`hidden lg:block absolute h-16 w-full left-24 top-5 z-50 text-xl`}
        >
          {modelData.title}
        </h2>
        <ResponsiveContainer width="100%" height={450}>
          <ComposedChart
            data={data}
            margin={{ top: 24, right: 30, left: -30, bottom: 20 }}
            className=""
          >
            <XAxis
              dataKey="dateDisplay"
              interval={"preserveStartEnd"}
              axisLine={true}
              tick={<CustomXLabel />}
            ></XAxis>
            <YAxis
              width={110}
              dataKey={`div2Days`}
              tick={<CustomYLabel />}
              label={{
                value: "2-Days Infection Values",
                angle: -90,
                position: "insideLeft",
              }}
            />
            <Tooltip content={renderTooltip} />
            <Legend
              align="right"
              verticalAlign="top"
              height={48}
              iconSize={18}
              iconType="rect"
              payload={[
                { value: "Low", type: "rect", color: "#43946C" },
                { value: "Moderate", type: "rect", color: "#F6D566" },
                { value: "High", type: "rect", color: "#CA3A32" },
              ]}
            ></Legend>
            <Bar dataKey="div2Days" maxBarSize={30}>
              {data.map((d) => {
                return (
                  <Cell
                    key={d.dateDisplay}
                    cursor="pointer"
                    fill={d.riskValueForTwoDays.color}
                  ></Cell>
                )
              })}
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

import React from "react"

export default function MoreInfo() {
  return (
    <ul className="px-4 mt-6 text-sm text-left list-disc">
      <li className="mb-4">
        <a
          href="https://live-cu-newa.pantheonsite.io/wp-content/uploads/more-info-cercospora-leaf-spot-of-table-beets-20201221.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          About the Cercospora Leaf Spot of Table Beet Model
        </a>
      </li>
    </ul>
  )
}

import React from "react"

export default function Acknowledgements() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        This Cercospora leaf spot of beets model was developed by Sarah
        Pethybridge (<a href="mailto:sjp277@cornell.edu">sjp277@cornell.edu</a>)
        and Julie Kikkert (
        <a href="mailto:jrk2@cornell.edu">jrk2@cornell.edu</a>) in collaboration
        with the{" "}
        <a href="http://newa.cornell.edu">
          Network for Environment and Weather Applications{" "}
        </a>
        which is part of the{" "}
        <a href="https://nysipm.cornell.edu">New York State IPM Program</a> and
        the{" "}
        <a href="http://nrcc.cornell.edu">Northeast Regional Climate Center</a>
        at Cornell University.
      </p>

      <p className="text-sm leading-5 text-left text-gray-500">
        This research was supported by the United States Department of
        Agriculture National Institute of Food and Agriculture (USDA-NIFA) Hatch
        project NYG-625424, USDA-NIFA Specialty Crop Block project SCG 18 001
        managed by the New York Farm Viability Institute, the New York Vegetable
        Research Association and Council, and USDA-NIFA Crop Protection and Pest
        Management Applied Research and Development Program project number
        2016-07645.
      </p>
    </>
  )
}

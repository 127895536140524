import { lookupTable } from "./_lookupTable"

function dailyInfectionValue(rhumArr, tempArr) {
  let hoursWithRhAbove89 = []
  let tempsAtRhAbove89 = []
  rhumArr.forEach((r, i) => {
    if (r !== "M" && r >= 90) {
      if (tempArr[i] !== "M") {
        hoursWithRhAbove89.push(r)
        tempsAtRhAbove89.push(tempArr[i])
      }
    }
  })

  let div = 0
  if (hoursWithRhAbove89.length !== 0) {
    const mint = Math.min(...tempsAtRhAbove89)
    const maxt = Math.max(...tempsAtRhAbove89)
    const avgt = Math.round((mint + maxt) / 2)

    if (avgt >= 59 && avgt <= 94) {
      div = lookupTable[`${hoursWithRhAbove89.length}`][avgt]
    }
  }

  return div
}

export default function BeetCercosporaLogic(data, dateOfInterest) {
  let div2Days = 0
  let div14Days = 0
  let div21Days = 0
  let cumulativeDiv = 0
  let riskValueForTwoDays = { label: "", color: "" }

  let divs = []
  const mData = data.dailyData.map((day, i) => {
    const div = dailyInfectionValue(day.rhum, day.temp)
    divs.push(div)

    if (i >= 1) {
      div2Days = divs.slice(i - 1).reduce((a, b) => a + b, 0)
      if (div2Days >= 0 && div2Days <= 3)
        riskValueForTwoDays = { label: "Low", color: "#43946C" }
      if (div2Days >= 4 && div2Days <= 6)
        riskValueForTwoDays = { label: "Medium", color: "#F6D566" }
      if (div2Days > 6)
        riskValueForTwoDays = { label: "High", color: "#CA3A32" }
    }

    if (i >= 14) {
      div14Days = divs.slice(i - 14).reduce((a, b) => a + b, 0)
    }

    if (i >= 21) {
      div21Days = divs.slice(i - 21).reduce((a, b) => a + b, 0)
    }

    cumulativeDiv += div

    let p = {
      date: day.date,
      dateDisplay: day.date.toLocaleDateString(),
      dayOfYear: day.dayOfYear,
      isForecast: day.isForecast,
      div,
      div2Days,
      div14Days,
      div21Days,
      cumulativeDiv,
      riskValueForTwoDays,
    }
    return p
  })

  ////////////////////////////////////////////////////////////////////////////////////
  let selectedDate = mData.find(
    (day) => day.dayOfYear === dateOfInterest.dayOfYear
  )
  if (!selectedDate) {
    selectedDate = mData.find((d) => d.isForecast)
  }

  // Data to display in Results Table --------------------------------------------------
  let tableData = [...mData]
  if (mData.length >= 8) {
    tableData = mData.slice(
      dateOfInterest.dayOfYear - 3,
      dateOfInterest.dayOfYear + 5
    )
  }
  // console.log(tableData)
  // //////////////////////////////////////////////////////////////////////////////////////

  // Data in CSV format to export ------------------------------------------------------
  let csvData = []
  csvData = mData.map((day) => {
    return {
      Date: day.dateDisplay,
      DIV: day.div,
      "2-DIV": day.div2Days,
      "14-DIV": day.div14Days,
      "21-DIV": day.div21Days,
      Seasonal: day.cumulativeDiv,
    }
  })
  // console.log(csvData)
  //////////////////////////////////////////////////////////////////////////////////////

  // Data in CSV format to export ------------------------------------------------------
  let graphData = [...mData]

  const indexAboveZero = mData.findIndex((d) => d.div2Days > 0)
  if (indexAboveZero > 0) {
    graphData = graphData.slice(indexAboveZero - 1, selectedDate.dayOfYear)
  }
  //////////////////////////////////////////////////////////////////////////////////////

  // Dashboard Summary -----------------------------------------------------------------
  let dashboardSummary = {
    message: "",
    div2Days: "",
  }

  const lastDate = mData.find((d) => d.isForecast)
  if (lastDate) {
    dashboardSummary["message"] = lastDate.riskValueForTwoDays.label
    dashboardSummary["div2Days"] = lastDate.div2Days
  }
  //////////////////////////////////////////////////////////////////////////////////////

  return {
    data: mData,
    selectedDate,
    tableData,
    graphData,
    csvData,
    dashboardSummary,
  }
}

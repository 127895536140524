export const lookupTable = {
  1: {
    59: 0,
    60: 0,
    61: 0,
    62: 0,
    63: 0,
    64: 0,
    65: 0,
    66: 0,
    67: 0,
    68: 0,
    69: 0,
    70: 0,
    71: 0,
    72: 1,
    73: 1,
    74: 1,
    75: 1,
    76: 1,
    77: 1,
    78: 1,
    79: 1,
    80: 1,
    81: 1,
    82: 1,
    83: 1,
    84: 1,
    85: 1,
    86: 1,
    87: 1,
    88: 1,
    89: 1,
    90: 1,
    91: 1,
    92: 1,
    93: 1,
    94: 1,
  },
  2: {
    59: 0,
    60: 0,
    61: 0,
    62: 0,
    63: 0,
    64: 0,
    65: 0,
    66: 0,
    67: 0,
    68: 0,
    69: 0,
    70: 0,
    71: 1,
    72: 1,
    73: 1,
    74: 1,
    75: 1,
    76: 1,
    77: 1,
    78: 1,
    79: 1,
    80: 1,
    81: 1,
    82: 1,
    83: 1,
    84: 1,
    85: 1,
    86: 1,
    87: 1,
    88: 1,
    89: 1,
    90: 1,
    91: 1,
    92: 1,
    93: 1,
    94: 1,
  },
  3: {
    59: 0,
    60: 0,
    61: 0,
    62: 0,
    63: 0,
    64: 0,
    65: 0,
    66: 0,
    67: 0,
    68: 0,
    69: 0,
    70: 1,
    71: 1,
    72: 1,
    73: 1,
    74: 1,
    75: 1,
    76: 1,
    77: 1,
    78: 1,
    79: 2,
    80: 2,
    81: 2,
    82: 2,
    83: 2,
    84: 2,
    85: 2,
    86: 2,
    87: 2,
    88: 2,
    89: 2,
    90: 2,
    91: 2,
    92: 2,
    93: 2,
    94: 2,
  },
  4: {
    59: 0,
    60: 0,
    61: 0,
    62: 0,
    63: 0,
    64: 1,
    65: 1,
    66: 1,
    67: 1,
    68: 1,
    69: 1,
    70: 1,
    71: 1,
    72: 1,
    73: 1,
    74: 1,
    75: 1,
    76: 1,
    77: 1,
    78: 2,
    79: 2,
    80: 2,
    81: 2,
    82: 2,
    83: 2,
    84: 2,
    85: 2,
    86: 2,
    87: 2,
    88: 2,
    89: 2,
    90: 2,
    91: 2,
    92: 2,
    93: 2,
    94: 2,
  },
  5: {
    59: 0,
    60: 0,
    61: 0,
    62: 0,
    63: 0,
    64: 1,
    65: 1,
    66: 1,
    67: 1,
    68: 1,
    69: 1,
    70: 1,
    71: 1,
    72: 1,
    73: 1,
    74: 1,
    75: 1,
    76: 1,
    77: 2,
    78: 2,
    79: 3,
    80: 3,
    81: 3,
    82: 3,
    83: 3,
    84: 3,
    85: 3,
    86: 3,
    87: 3,
    88: 3,
    89: 3,
    90: 3,
    91: 3,
    92: 3,
    93: 3,
    94: 3,
  },
  6: {
    59: 0,
    60: 0,
    61: 0,
    62: 0,
    63: 0,
    64: 1,
    65: 1,
    66: 1,
    67: 1,
    68: 1,
    69: 1,
    70: 1,
    71: 1,
    72: 2,
    73: 2,
    74: 2,
    75: 2,
    76: 2,
    77: 2,
    78: 2,
    79: 3,
    80: 4,
    81: 4,
    82: 4,
    83: 4,
    84: 4,
    85: 4,
    86: 4,
    87: 4,
    88: 4,
    89: 4,
    90: 4,
    91: 4,
    92: 4,
    93: 4,
    94: 3,
  },
  7: {
    59: 0,
    60: 0,
    61: 0,
    62: 0,
    63: 1,
    64: 2,
    65: 2,
    66: 2,
    67: 2,
    68: 2,
    69: 2,
    70: 2,
    71: 2,
    72: 2,
    73: 2,
    74: 2,
    75: 2,
    76: 2,
    77: 2,
    78: 3,
    79: 4,
    80: 4,
    81: 4,
    82: 4,
    83: 4,
    84: 4,
    85: 4,
    86: 4,
    87: 4,
    88: 4,
    89: 4,
    90: 4,
    91: 4,
    92: 4,
    93: 4,
    94: 3,
  },
  8: {
    59: 0,
    60: 0,
    61: 0,
    62: 0,
    63: 1,
    64: 2,
    65: 2,
    66: 2,
    67: 2,
    68: 2,
    69: 2,
    70: 2,
    71: 2,
    72: 2,
    73: 2,
    74: 2,
    75: 2,
    76: 2,
    77: 3,
    78: 3,
    79: 5,
    80: 5,
    81: 5,
    82: 5,
    83: 5,
    84: 5,
    85: 5,
    86: 5,
    87: 5,
    88: 5,
    89: 5,
    90: 5,
    91: 5,
    92: 5,
    93: 5,
    94: 3,
  },
  9: {
    59: 0,
    60: 0,
    61: 0,
    62: 0,
    63: 2,
    64: 2,
    65: 2,
    66: 2,
    67: 2,
    68: 2,
    69: 2,
    70: 2,
    71: 2,
    72: 2,
    73: 2,
    74: 2,
    75: 3,
    76: 3,
    77: 3,
    78: 4,
    79: 5,
    80: 6,
    81: 6,
    82: 6,
    83: 6,
    84: 6,
    85: 6,
    86: 6,
    87: 6,
    88: 6,
    89: 6,
    90: 6,
    91: 6,
    92: 6,
    93: 6,
    94: 3,
  },
  10: {
    59: 0,
    60: 0,
    61: 0,
    62: 0,
    63: 2,
    64: 2,
    65: 2,
    66: 2,
    67: 2,
    68: 2,
    69: 2,
    70: 3,
    71: 3,
    72: 3,
    73: 3,
    74: 3,
    75: 3,
    76: 3,
    77: 4,
    78: 4,
    79: 6,
    80: 6,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
  11: {
    59: 0,
    60: 0,
    61: 0,
    62: 0,
    63: 2,
    64: 3,
    65: 3,
    66: 3,
    67: 3,
    68: 3,
    69: 3,
    70: 3,
    71: 3,
    72: 3,
    73: 3,
    74: 3,
    75: 3,
    76: 3,
    77: 4,
    78: 5,
    79: 6,
    80: 7,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
  12: {
    59: 0,
    60: 0,
    61: 0,
    62: 0,
    63: 2,
    64: 3,
    65: 3,
    66: 3,
    67: 3,
    68: 3,
    69: 3,
    70: 3,
    71: 3,
    72: 4,
    73: 4,
    74: 4,
    75: 4,
    76: 4,
    77: 4,
    78: 5,
    79: 7,
    80: 7,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
  13: {
    59: 0,
    60: 0,
    61: 0,
    62: 0,
    63: 3,
    64: 3,
    65: 3,
    66: 3,
    67: 3,
    68: 3,
    69: 3,
    70: 3,
    71: 4,
    72: 4,
    73: 4,
    74: 4,
    75: 4,
    76: 4,
    77: 5,
    78: 6,
    79: 7,
    80: 7,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
  14: {
    59: 0,
    60: 0,
    61: 0,
    62: 1,
    63: 3,
    64: 3,
    65: 3,
    66: 3,
    67: 3,
    68: 3,
    69: 3,
    70: 4,
    71: 4,
    72: 4,
    73: 4,
    74: 4,
    75: 4,
    76: 4,
    77: 5,
    78: 6,
    79: 7,
    80: 7,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
  15: {
    59: 0,
    60: 0,
    61: 0,
    62: 1,
    63: 3,
    64: 4,
    65: 4,
    66: 4,
    67: 4,
    68: 4,
    69: 4,
    70: 4,
    71: 4,
    72: 4,
    73: 4,
    74: 4,
    75: 4,
    76: 5,
    77: 6,
    78: 6,
    79: 7,
    80: 7,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
  16: {
    59: 0,
    60: 0,
    61: 0,
    62: 2,
    63: 3,
    64: 4,
    65: 4,
    66: 4,
    67: 4,
    68: 4,
    69: 4,
    70: 4,
    71: 4,
    72: 4,
    73: 4,
    74: 4,
    75: 5,
    76: 5,
    77: 6,
    78: 7,
    79: 7,
    80: 7,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
  17: {
    59: 0,
    60: 0,
    61: 1,
    62: 2,
    63: 3,
    64: 4,
    65: 4,
    66: 4,
    67: 4,
    68: 4,
    69: 4,
    70: 4,
    71: 5,
    72: 5,
    73: 5,
    74: 5,
    75: 5,
    76: 5,
    77: 6,
    78: 7,
    79: 7,
    80: 7,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
  18: {
    59: 0,
    60: 0,
    61: 1,
    62: 2,
    63: 3,
    64: 4,
    65: 4,
    66: 4,
    67: 4,
    68: 4,
    69: 4,
    70: 5,
    71: 5,
    72: 5,
    73: 5,
    74: 5,
    75: 5,
    76: 6,
    77: 7,
    78: 7,
    79: 7,
    80: 7,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
  19: {
    59: 0,
    60: 0,
    61: 1,
    62: 3,
    63: 4,
    64: 5,
    65: 5,
    66: 5,
    67: 5,
    68: 5,
    69: 5,
    70: 5,
    71: 5,
    72: 6,
    73: 6,
    74: 6,
    75: 6,
    76: 6,
    77: 7,
    78: 7,
    79: 7,
    80: 7,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
  20: {
    59: 0,
    60: 1,
    61: 2,
    62: 3,
    63: 4,
    64: 5,
    65: 5,
    66: 5,
    67: 5,
    68: 5,
    69: 5,
    70: 5,
    71: 6,
    72: 6,
    73: 6,
    74: 6,
    75: 6,
    76: 6,
    77: 7,
    78: 7,
    79: 7,
    80: 7,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
  21: {
    59: 0,
    60: 1,
    61: 2,
    62: 4,
    63: 4,
    64: 5,
    65: 5,
    66: 5,
    67: 5,
    68: 5,
    69: 5,
    70: 6,
    71: 6,
    72: 6,
    73: 6,
    74: 6,
    75: 6,
    76: 7,
    77: 7,
    78: 7,
    79: 7,
    80: 7,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
  22: {
    59: 1,
    60: 1,
    61: 3,
    62: 4,
    63: 5,
    64: 6,
    65: 6,
    66: 6,
    67: 6,
    68: 6,
    69: 6,
    70: 6,
    71: 6,
    72: 6,
    73: 6,
    74: 6,
    75: 6,
    76: 7,
    77: 7,
    78: 7,
    79: 7,
    80: 7,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
  23: {
    59: 1,
    60: 2,
    61: 3,
    62: 4,
    63: 5,
    64: 6,
    65: 6,
    66: 6,
    67: 6,
    68: 6,
    69: 6,
    70: 6,
    71: 6,
    72: 6,
    73: 6,
    74: 6,
    75: 7,
    76: 7,
    77: 7,
    78: 7,
    79: 7,
    80: 7,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
  24: {
    59: 1,
    60: 2,
    61: 4,
    62: 5,
    63: 5,
    64: 6,
    65: 6,
    66: 6,
    67: 6,
    68: 6,
    69: 6,
    70: 6,
    71: 6,
    72: 7,
    73: 7,
    74: 7,
    75: 7,
    76: 7,
    77: 7,
    78: 7,
    79: 7,
    80: 7,
    81: 7,
    82: 7,
    83: 7,
    84: 7,
    85: 7,
    86: 7,
    87: 7,
    88: 7,
    89: 7,
    90: 7,
    91: 7,
    92: 7,
    93: 7,
    94: 3,
  },
}

import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Pethybridge, S.J., Sharma, S., Hansen, Z., Kikkert, J.R., Olmstead,
        D.L., and Hanson, L.E. 2020. Optimizing Cercospora Leaf Spot Control in
        Table Beet Using Action Thresholds and Disease Forecasting. Plant
        Disease. 104 (6) pp 1831-1840.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Windels, C.E., Lamey, H.A., Hilde, D., Widner, J., and Knudsen, T. 1998.
        A Cercospora Leaf Spot Model for Sugar Beet. Plan Disease. 82 (7) pp
        716-726.
      </p>
    </>
  )
}
